import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { t } from '../../../domain/services/configService';
import { theme } from '../../../domain/theme';
import { ParagraphWrapper } from '../ParagraphWrapper';

const stylePullQuote = css`
  background: #000;
  height: auto;
  width: 100%;
  padding: 30px;
  margin-bottom: 40px;

  & h3 {
    color: #fff;
    font-family: Georgia, Times, 'Times New Roman', serif;
    font-size: 1.625rem;
    margin: 0;
    line-height: 2.25rem;
    letter-spacing: -0.01em;
    text-indent: -0.5em;
    font-weight: 100;
  }

  &.center {
    text-align: center;
  }

  ${theme.mq.tablet} {
    & {
      margin-bottom: 0;
    }
  }
`;

const pullQuoteAuthor = css`
  ${theme.font.family('boldWeb')};
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 2.5px;
  line-height: 1.5;

  color: #fff;
  margin: 30px 0 6px;
  text-transform: uppercase;
  font-weight: 100;
`;

const pullQuoteName = css`
  font-weight: 700;
`;

const pullQuoteSource = css`
  ${theme.font.family('boldWeb')};
  font-weight: 400;
  font-size: 0.75rem;
  letter-spacing: 2.5px;
  line-height: 1.5;

  color: #999;
  text-transform: uppercase;
`;

const pullQuoteLarge = css`
  ${theme.mq.tablet} {
    & {
      padding: 40px;
      display: inline-block;
      margin-bottom: 40px;
    }

    & h3 {
      font-size: 2.8125rem;
      line-height: 4.0625rem;
      letter-spacing: -0.01em;
    }

    & .pull-quote__author,
    & .pull-quote__source {
      display: inline-block;
      font-size: 0.875rem;
      line-height: 0.875rem;
      padding: 0 20px;
    }

    & .pull-quote__author {
      margin: 30px 0 0;
      border-left: 1px solid #999;
      border-right: 1px solid #999;
      position: relative;
    }

    & .pull-quote__author::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: -1px;
      width: 1px;
      height: 2px;
      background: #000;
      z-index: 2;
    }

    & .pull-quote__author::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -1px;
      width: 1px;
      height: 2px;
      background: #000;
      z-index: 2;
    }

    & .pull-quote__source {
      border-right: 1px solid #999;
      position: relative;
    }

    & .pull-quote__source::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: -1px;
      width: 1px;
      height: 2px;
      background: #000;
      z-index: 2;
    }

    & .pull-quote__source::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: -1px;
      width: 1px;
      height: 2px;
      background: #000;
      z-index: 2;
    }
  }

  ${theme.mq.small_desktop} {
    & {
      padding: 26px 60px 46px;
      margin-bottom: 30px;
    }

    & .pull-quote h3 {
      font-size: 2.5rem;
      line-height: 3.4375rem;
      width: 605px;
    }
  }

  ${theme.mq.large_desktop} {
    & h3 {
      font-size: 2.8125rem;
      line-height: 3.75rem;
    }
  }
`;

const pullQuoteSmall = css`
  ${theme.mq.tablet} {
    padding: 30px;
    float: left;
    display: block;
    margin-right: 2.33817%;
    width: 48.83092%;

    & h3 {
      font-size: 1.75rem;
      line-height: 2.25rem;
    }

    & .pull-quote__author {
      font-size: 0.75rem;
    }

    & .pull-quote__source {
      font-size: 0.75rem;
    }
  }

  ${theme.mq.small_desktop} {
    float: left;
    display: block;
    margin-right: 2.33817%;
    width: 100%;
    margin: 0 30px 28px 0;
    max-width: 315px;

    & h3 {
      font-size: 1.875rem;
      line-height: 2.4375rem;
      letter-spacing: 0;
    }
  }

  ${theme.mq.large_desktop} {
    padding: 30px 60px;
    max-width: 420px;
  }
`;
const PullQuoteInner = props => {
  const { pullQuote, isLarge, contributor, source } = props;
  const classes = isLarge
    ? 'pull-quote pull-quote--large'
    : 'pull-quote pull-quote--small';
  return (
    <div
      className={classes}
      css={[stylePullQuote, isLarge ? pullQuoteLarge : pullQuoteSmall]}
    >
      <h3>{`“${pullQuote}”`}</h3>
      {contributor && (
        <div className="pull-quote__author" css={pullQuoteAuthor}>
          {`${t('by')} `}
          <span className="pull-quote__name" css={pullQuoteName}>
            {contributor}
          </span>
        </div>
      )}
      {source && (
        <div className="pull-quote__source" css={pullQuoteSource}>
          {source}
        </div>
      )}
    </div>
  );
};

PullQuoteInner.propTypes = {
  pullQuote: PropTypes.string.isRequired,
  isLarge: PropTypes.bool,
  contributor: PropTypes.string,
  source: PropTypes.string
};

PullQuoteInner.defaultProps = {
  isLarge: false,
  contributor: undefined,
  source: undefined
};

export const PullQuote = ParagraphWrapper(PullQuoteInner);
